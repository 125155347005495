import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./events.css";
import Header from "../../Components/Header/header"
import Footer from "../../Components/Footer/footer"
import Breadcrumb from "../../Components/Breadcrumb/breadcrumb";
import Event1 from "../../Assets/event1.jpg"
import Event2 from "../../Assets/event2.png"
import Event3 from "../../Assets/event3.png"
import { FaCalendarDays, FaRegClock, FaLocationDot } from "react-icons/fa6";
import LazyImage from '../../Components/LazyLoader/LazyImage';

export default function Events(){
    const location = useLocation();
    const title = location.pathname === '/events/upcoming-events/' ? 'Upcoming Events' : location.pathname === '/events/past-events/' ? 'Past Events' : 'Upcoming Events';
    return(
        <React.Fragment>
            <Header/>
            <Breadcrumb title={title}/>
            <section className="events eventspage">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                            <div className="eventgrid">
                                <div className="eventgridimg">
                                    <Link to="#"><LazyImage src={Event1} alt="Event" /></Link>
                                </div>
                                <div className="eventcontent">
                                    <div className="eventgridhead">
                                        <h2><Link to="#">Safe Conversations, Stronger Relationships</Link></h2>
                                    </div>
                                    <div className="eventmetagrid">
                                        <div className="eventdate">
                                            <p><FaCalendarDays/> 16 Oct, 2024</p>
                                        </div>
                                        <div className="eventtime">
                                            <p><FaRegClock/> 9:00 PM – 10:00 PM</p>
                                        </div>
                                        <div className="eventlocation">
                                            <p><FaLocationDot/> Zoom</p>
                                        </div>
                                    </div>
                                    <div className="eventshordis">
                                        <p>Join Women Business Connect for an open, supportive space where you can share your heart, connect with others, and discover gentle tools to navigate relationships with more love and balance.</p>
                                    </div>
                                    <div className="eventbtnsbook">
                                        <Link to="#">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                            <div className="eventgrid">
                                <div className="eventgridimg">
                                    <Link to="#"><LazyImage src={Event2} alt="Event" /></Link>
                                </div>
                                <div className="eventcontent">
                                    <div className="eventgridhead">
                                        <h2><Link to="#">Women Business Connect - Global Networking Hour</Link></h2>
                                    </div>
                                    <div className="eventmetagrid">
                                        <div className="eventdate">
                                            <p><FaCalendarDays/> 23 Oct, 2024</p>
                                        </div>
                                        <div className="eventtime">
                                            <p><FaRegClock/> 9:00 PM – 10:00 PM GMT+8</p>
                                        </div>
                                        <div className="eventlocation">
                                            <p><FaLocationDot/> Zoom</p>
                                        </div>
                                    </div>
                                    <div className="eventshordis">
                                        <p>Join us for a power networking hour to connect with other women in business globally and build meaningful relationships!</p>
                                    </div>
                                    <div className="eventbtnsbook">
                                        <Link to="#">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                            <div className="eventgrid">
                                <div className="eventgridimg">
                                    <Link to="#"><LazyImage src={Event3} alt="Event" /></Link>
                                </div>
                                <div className="eventcontent">
                                    <div className="eventgridhead">
                                        <h2><Link to="#">Women Business Connect - Global Networking Hour</Link></h2>
                                    </div>
                                    <div className="eventmetagrid">
                                        <div className="eventdate">
                                            <p><FaCalendarDays/> 09 Oct, 2024</p>
                                        </div>
                                        <div className="eventtime">
                                            <p><FaRegClock/> 9:00 PM – 10:00 PM GMT+8</p>
                                        </div>
                                        <div className="eventlocation">
                                            <p><FaLocationDot/> Zoom</p>
                                        </div>
                                    </div>
                                    <div className="eventshordis">
                                        <p>Join us for a power networking hour to connect with other women in business globally and build meaningful relationships!</p>
                                    </div>
                                    <div className="eventbtnsbook">
                                        <Link to="#">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                            <div className="eventgrid">
                                <div className="eventgridimg">
                                    <Link to="#"><LazyImage src={Event3} alt="Event" /></Link>
                                </div>
                                <div className="eventcontent">
                                    <div className="eventgridhead">
                                        <h2><Link to="#">Women Business Connect - Global Networking Hour</Link></h2>
                                    </div>
                                    <div className="eventmetagrid">
                                        <div className="eventdate">
                                            <p><FaCalendarDays/> 09 Oct, 2024</p>
                                        </div>
                                        <div className="eventtime">
                                            <p><FaRegClock/> 9:00 PM – 10:00 PM GMT+8</p>
                                        </div>
                                        <div className="eventlocation">
                                            <p><FaLocationDot/> Zoom</p>
                                        </div>
                                    </div>
                                    <div className="eventshordis">
                                        <p>Join us for a power networking hour to connect with other women in business globally and build meaningful relationships!</p>
                                    </div>
                                    <div className="eventbtnsbook">
                                        <Link to="#">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                            <div className="eventgrid">
                                <div className="eventgridimg">
                                    <Link to="#"><LazyImage src={Event3} alt="Event" /></Link>
                                </div>
                                <div className="eventcontent">
                                    <div className="eventgridhead">
                                        <h2><Link to="#">Women Business Connect - Global Networking Hour</Link></h2>
                                    </div>
                                    <div className="eventmetagrid">
                                        <div className="eventdate">
                                            <p><FaCalendarDays/> 09 Oct, 2024</p>
                                        </div>
                                        <div className="eventtime">
                                            <p><FaRegClock/> 9:00 PM – 10:00 PM GMT+8</p>
                                        </div>
                                        <div className="eventlocation">
                                            <p><FaLocationDot/> Zoom</p>
                                        </div>
                                    </div>
                                    <div className="eventshordis">
                                        <p>Join us for a power networking hour to connect with other women in business globally and build meaningful relationships!</p>
                                    </div>
                                    <div className="eventbtnsbook">
                                        <Link to="#">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                            <div className="eventgrid">
                                <div className="eventgridimg">
                                    <Link to="#"><LazyImage src={Event3} alt="Event" /></Link>
                                </div>
                                <div className="eventcontent">
                                    <div className="eventgridhead">
                                        <h2><Link to="#">Women Business Connect - Global Networking Hour</Link></h2>
                                    </div>
                                    <div className="eventmetagrid">
                                        <div className="eventdate">
                                            <p><FaCalendarDays/> 09 Oct, 2024</p>
                                        </div>
                                        <div className="eventtime">
                                            <p><FaRegClock/> 9:00 PM – 10:00 PM GMT+8</p>
                                        </div>
                                        <div className="eventlocation">
                                            <p><FaLocationDot/> Zoom</p>
                                        </div>
                                    </div>
                                    <div className="eventshordis">
                                        <p>Join us for a power networking hour to connect with other women in business globally and build meaningful relationships!</p>
                                    </div>
                                    <div className="eventbtnsbook">
                                        <Link to="#">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </React.Fragment>
    )
}