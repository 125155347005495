import React, { useState } from "react";
import { Link, useNavigate} from "react-router-dom";
import "../Login/login.css";
import Header from "../../../Components/Header/header"
import Footer from "../../../Components/Footer/footer"
import LoginBanner from "../../../Assets/aboutbanner/slide2.webp"
import { FaEnvelope, FaEye, FaLock, FaEyeSlash, FaUser, FaPhone, FaBriefcase, FaGlobe, FaLinkedinIn } from "react-icons/fa6";
import { RegisterForm } from "../../../API/my-account";
import LoginWithGoogle from "../../../Components/LoginWithGoogle"

export default function Login(){
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const togglePasswordVisibility = () => {
        setIsPasswordVisible((prevVisibility) => !prevVisibility);
    };
    /*------------Register Form -----------------*/
    const [formData, setFormData] = useState({
        first_name: '', last_name: '', phone: '', email: '', business_name: '', country: '', linkedin_url: '', password: ''
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    
    const validateForm = () => {
        const errors = {};
        if (!formData.first_name || /\d/.test(formData.first_name)) errors.first_name = 'Please enter a valid name!';
        if (!formData.last_name || /\d/.test(formData.last_name)) errors.last_name = 'Please enter a valid name!';
        if (!formData.phone || !/^\d+$/.test(formData.phone)) errors.phone = 'Please enter a valid phone number!';
        if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) errors.email = 'Please enter a valid email id!';
        if (!formData.business_name) errors.business_name = 'Please enter your business name!';
        if (!formData.country || /\d/.test(formData.country)) errors.country = 'Please enter a valid country name!';
        const linkedInRegex = /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|company|pub|profile)\/[A-Za-z0-9_-]+\/?$/;
        if (!formData.linkedin_url) {
            errors.linkedin_url = 'LinkedIn URL is required';
        } else if (!linkedInRegex.test(formData.linkedin_url)) {
            errors.linkedin_url = 'Invalid LinkedIn URL';
        }
        if (!formData.password) {
            errors.password = 'Password is required';
        } else if (formData.password.length < 8) {
            errors.password = 'Password must be at least 8 characters long';
        }
        return errors;
    };
    const handleChange = ({ target: { name, value } }) => {
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        const formErrors = validateForm();
        setErrors(formErrors);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length) {
            setErrors(formErrors);
            return;
        }
        setLoading(true);
        setErrorMessage('');
        try {
            const data = await RegisterForm(formData);
            setFormData({ first_name: '', last_name: '', phone: '', email: '', business_name: '', country: '', linkedin_url: '', password: '' });
            setErrors({});
            if (data.status === true) {
                navigate('/thank-you/');
            }
        } catch {
            setErrorMessage('Something went wrong, please try again later.');
        } finally {
            setLoading(false);
            //navigate('/thank-you/');
        }
    };
    
    return(
        <React.Fragment>
            <Header/>
            <section className="loginsep"></section>
            <section className="loginscreen">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="loginform">
                                <h2>Register</h2>
                                <p>Please enter details</p>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="first_name">First & Middle Name <span>*</span></label>
                                                <div className="inputicons"><FaUser/></div>
                                                <input type="text" id="first_name" name="first_name" className="form-control" placeholder="Enter name" onChange={handleChange} />
                                                {errors.first_name && <p className="error">{errors.first_name}</p>}
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="last_name">Last Name <span>*</span></label>
                                                <div className="inputicons"><FaUser/></div>
                                                <input type="text" id="last_name" name="last_name" className="form-control" placeholder="Enter name" onChange={handleChange} />
                                                {errors.last_name && <p className="error">{errors.last_name}</p>}
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <div className="inputicons"><FaEnvelope/></div>
                                                <label htmlFor="email">Email <span>*</span></label>
                                                <input type="email" id="email" name="email" className="form-control" placeholder="Enter email" onChange={handleChange} />
                                                {errors.email && <p className="error">{errors.email}</p>}
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="phone">Phone Number <span>*</span></label>
                                                <div className="inputicons"><FaPhone/></div>
                                                <input type="number" min="0" id="phone" name="phone" className="form-control" placeholder="Enter phone number" onChange={handleChange} />
                                                {errors.phone && <p className="error">{errors.phone}</p>}
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <label htmlFor="business_name">Business Name <span>*</span></label>
                                                <div className="inputicons"><FaBriefcase/></div>
                                                <input type="text" id="business_name" name="business_name" className="form-control" placeholder="Enter business name" onChange={handleChange} />
                                                {errors.business_name && <p className="error">{errors.business_name}</p>}
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="country">Country <span>*</span></label>
                                                <div className="inputicons"><FaGlobe/></div>
                                                <input type="text" id="country" name="country" className="form-control" placeholder="Enter country name" onChange={handleChange} />
                                                {errors.country && <p className="error">{errors.country}</p>}
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="linkedin_url">Linkedin URL <span>*</span></label>
                                                <div className="inputicons"><FaLinkedinIn/></div>
                                                <input type="text" id="linkedin_url" name="linkedin_url" className="form-control" placeholder="Linkedin URL" onChange={handleChange} />
                                                {errors.linkedin_url && <p className="error">{errors.linkedin_url}</p>}
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <label htmlFor="password">Password <span>*</span></label>
                                                <div className="inputicons"><FaLock/></div>
                                                <input type={isPasswordVisible ? "text" : "password"} id="password" name="password" className="form-control" placeholder="••••••" onChange={handleChange} />
                                                {errors.password && <p className="error">{errors.password}</p>}
                                                <div className="toggle-password" onClick={togglePasswordVisibility}>{isPasswordVisible ? <FaEyeSlash /> : <FaEye />}</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="signinbtns">
                                                <button type="submit">Register</button>
                                                {errorMessage && <div className="successmessage"><p className="error">{errorMessage}</p></div>}
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                {loading && (
                                    <div className="formloader">
                                        <div className="spinner"></div>
                                    </div>
                                )}
                                <div className="otherloginseprator">
                                    <p>OR</p>
                                </div>
                               <LoginWithGoogle/>
                                <div className="dontacount">
                                    <p>Already have a account? <Link to="/login/">Login</Link></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="loginimagearea registerscreen">
                                <img src={LoginBanner} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </React.Fragment>
    )
}