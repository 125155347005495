import React from "react";
import Header from "../../../Components/Header/header"
import Footer from "../../../Components/Footer/footer"
import Sidebar from "../../../Components/UserSidebar"

export default function Dashboard(){
    return(
        <React.Fragment>
            <Header/>
            <section className="loginsep"></section>
            <section className="dashboard">
                <div className="container">
                    <div className="row">
                        <Sidebar/>
                        <div className="col-lg-9 col-md-9 col-sm-9 col-9">
                            <div className="userprofilese">
                                <form action="" method="post">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </React.Fragment>
    )
}