import React from "react";
import { Link } from "react-router-dom";
import "./blogs.css";
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Breadcrumb from "../../Components/Breadcrumb/breadcrumb";
import LazyImage from '../../Components/LazyLoader/LazyImage';
import Event1 from "../../Assets/event1.jpg"
import { IoSearchOutline } from "react-icons/io5";
import Author from "../../Assets/author.jpg"
import { FaCalendarDays, FaEye } from "react-icons/fa6";
import { CiHeart } from "react-icons/ci";

export default function Blogs(){
    return(
        <React.Fragment>
            <Header/>
            <Breadcrumb title={'Articles'}/>
            <section className="bloglist">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="blogsearchpart">
                                <h2>Read our latest Articles</h2>
                                <form action="" method="post">
                                    <input type="search" name="search" className="form-control" placeholder="Type Keywords to Search..." />
                                    <button type="submit"><IoSearchOutline/></button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="bloggrid">
                                <div className="blogimg">
                                    <Link to="#"><LazyImage src={Event1} alt="Blog" /></Link>
                                </div>
                                <div className="blogheading">
                                    <h2><Link to="#">Emotions: Women Business Connect - Networking Hour</Link></h2>
                                </div>
                                <div className="blogmeta">
                                    <div className="blogauthor">
                                        <div className="autorimg">
                                            <LazyImage src={Author} alt="Hana" />
                                        </div>
                                        <div className="authorname">
                                            <h3>Hana Mohamed</h3>
                                        </div>
                                    </div>
                                    <div className="postdate">
                                        <p><FaCalendarDays/> 12 October, 2024</p>
                                    </div>
                                </div>
                                <div className="blogcontent">
                                    <p>Emotions. I've hosted Women Business Connect - Networking Hour countless times, yet each time, as we prepare to begin, my stomach...</p>
                                </div>
                                <div className="blogfooter">
                                    <div className="readmorebtnblog">
                                        <Link to="">Read More</Link>
                                    </div>
                                    <div className="footerotherblog">
                                        <div className="totalviewblog">
                                            <p><FaEye/> 4</p>
                                        </div>
                                        <div className="bloglike">
                                            <p><CiHeart/> 0</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="bloggrid">
                                <div className="blogimg">
                                    <Link to="#"><LazyImage src={Event1} alt="Blog" /></Link>
                                </div>
                                <div className="blogheading">
                                    <h2><Link to="#">Are you running your business in a fog with no clarity?</Link></h2>
                                </div>
                                <div className="blogmeta">
                                    <div className="blogauthor">
                                        <div className="autorimg">
                                            <LazyImage src={Author} alt="Hana" />
                                        </div>
                                        <div className="authorname">
                                            <h3>Hana Mohamed</h3>
                                        </div>
                                    </div>
                                    <div className="postdate">
                                        <p><FaCalendarDays/> 20 October, 2024</p>
                                    </div>
                                </div>
                                <div className="blogcontent">
                                    <p>Driving in fog can be nerve-wracking. You can't see far ahead, and every turn feels like a gamble. It's a lot like navigating a business...</p>
                                </div>
                                <div className="blogfooter">
                                    <div className="readmorebtnblog">
                                        <Link to="">Read More</Link>
                                    </div>
                                    <div className="footerotherblog">
                                        <div className="totalviewblog">
                                            <p><FaEye/> 4</p>
                                        </div>
                                        <div className="bloglike">
                                            <p><CiHeart/> 0</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="bloggrid">
                                <div className="blogimg">
                                    <Link to="#"><LazyImage src={Event1} alt="Blog" /></Link>
                                </div>
                                <div className="blogheading">
                                    <h2><Link to="#">Networking Hour - Women Business Connect</Link></h2>
                                </div>
                                <div className="blogmeta">
                                    <div className="blogauthor">
                                        <div className="autorimg">
                                            <LazyImage src={Author} alt="Hana" />
                                        </div>
                                        <div className="authorname">
                                            <h3>Hana Mohamed</h3>
                                        </div>
                                    </div>
                                    <div className="postdate">
                                        <p><FaCalendarDays/> 20 October, 2024</p>
                                    </div>
                                </div>
                                <div className="blogcontent">
                                    <p>I'm thrilled to invite you to an exclusive online networking event just for you: Networking Hour - Women Business Connect! Join us for a...</p>
                                </div>
                                <div className="blogfooter">
                                    <div className="readmorebtnblog">
                                        <Link to="">Read More</Link>
                                    </div>
                                    <div className="footerotherblog">
                                        <div className="totalviewblog">
                                            <p><FaEye/> 4</p>
                                        </div>
                                        <div className="bloglike">
                                            <p><CiHeart/> 0</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="bloggrid">
                                <div className="blogimg">
                                    <Link to="#"><LazyImage src={Event1} alt="Blog" /></Link>
                                </div>
                                <div className="blogheading">
                                    <h2><Link to="#">International Women's Day: Inspiring Women To Lead</Link></h2>
                                </div>
                                <div className="blogmeta">
                                    <div className="blogauthor">
                                        <div className="autorimg">
                                            <LazyImage src={Author} alt="Hana" />
                                        </div>
                                        <div className="authorname">
                                            <h3>Hana Mohamed</h3>
                                        </div>
                                    </div>
                                    <div className="postdate">
                                        <p><FaCalendarDays/> 20 October, 2024</p>
                                    </div>
                                </div>
                                <div className="blogcontent">
                                    <p>As we celebrate International Women’s Day, I can’t help but reflect on the incredible journey of women in leadership, especially those...</p>
                                </div>
                                <div className="blogfooter">
                                    <div className="readmorebtnblog">
                                        <Link to="">Read More</Link>
                                    </div>
                                    <div className="footerotherblog">
                                        <div className="totalviewblog">
                                            <p><FaEye/> 4</p>
                                        </div>
                                        <div className="bloglike">
                                            <p><CiHeart/> 0</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="bloggrid">
                                <div className="blogimg">
                                    <Link to="#"><LazyImage src={Event1} alt="Blog" /></Link>
                                </div>
                                <div className="blogheading">
                                    <h2><Link to="#">Mastering Distractions for Business Success</Link></h2>
                                </div>
                                <div className="blogmeta">
                                    <div className="blogauthor">
                                        <div className="autorimg">
                                            <LazyImage src={Author} alt="Hana" />
                                        </div>
                                        <div className="authorname">
                                            <h3>Hana Mohamed</h3>
                                        </div>
                                    </div>
                                    <div className="postdate">
                                        <p><FaCalendarDays/> 20 October, 2024</p>
                                    </div>
                                </div>
                                <div className="blogcontent">
                                    <p>The entrepreneurial path is like navigating a thrilling adventure filled with opportunities, challenges and yes, distractions....</p>
                                </div>
                                <div className="blogfooter">
                                    <div className="readmorebtnblog">
                                        <Link to="">Read More</Link>
                                    </div>
                                    <div className="footerotherblog">
                                        <div className="totalviewblog">
                                            <p><FaEye/> 4</p>
                                        </div>
                                        <div className="bloglike">
                                            <p><CiHeart/> 0</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="bloggrid">
                                <div className="blogimg">
                                    <Link to="#"><LazyImage src={Event1} alt="Blog" /></Link>
                                </div>
                                <div className="blogheading">
                                    <h2><Link to="#">Mastering Distractions for Business Success</Link></h2>
                                </div>
                                <div className="blogmeta">
                                    <div className="blogauthor">
                                        <div className="autorimg">
                                            <LazyImage src={Author} alt="Hana" />
                                        </div>
                                        <div className="authorname">
                                            <h3>Hana Mohamed</h3>
                                        </div>
                                    </div>
                                    <div className="postdate">
                                        <p><FaCalendarDays/> 20 October, 2024</p>
                                    </div>
                                </div>
                                <div className="blogcontent">
                                    <p>The entrepreneurial path is like navigating a thrilling adventure filled with opportunities, challenges and yes, distractions....</p>
                                </div>
                                <div className="blogfooter">
                                    <div className="readmorebtnblog">
                                        <Link to="">Read More</Link>
                                    </div>
                                    <div className="footerotherblog">
                                        <div className="totalviewblog">
                                            <p><FaEye/> 4</p>
                                        </div>
                                        <div className="bloglike">
                                            <p><CiHeart/> 0</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </React.Fragment>
    )
}