import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import WOW from 'wowjs';
import 'animate.css';
import Home from "./Pages/Home";
import NotFound from './Pages/NotFound';
import Login from './Pages/Myaccount/Login';
import Register from './Pages/Myaccount/Register';
import Events from './Pages/Events';
import Services from './Pages/Services';
import Blogs from './Pages/Blogs';
import Contactus from './Pages/Contactus';
import GuestSpeaker from './Pages/BeOurGuestSpeaker';
import BusinessListing from './Pages/BusinessListing';
import Testimonials from './Pages/Testimonials';
import PrivateRoute from './Routes/PrivateRoute';
import DashboardRoutes from "./Routes/DashboardRoutes";

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};
function App() {
  useEffect(() => {
    new WOW.WOW().init();
  }, []);
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/events" element={<Events />} />
        <Route path="/events/*" element={<Events />} />
        <Route path="/scope-of-services" element={<Services />} />
        <Route path="/articles" element={<Blogs />} />
        <Route path="/contact-us" element={<Contactus />} />
        <Route path="/be-our-guest-speaker" element={<GuestSpeaker />} />
        <Route path="/business-listing" element={<BusinessListing />} />
        <Route path="/testimonials" element={<Testimonials />} />
        
        {/* Protected dashboard routes */}
        <Route path="/dashboard/*" element={
          <PrivateRoute>
            <DashboardRoutes />
          </PrivateRoute>
        } />

        {/* Catch-all route for 404 */}
        <Route path="*" element={<NotFound />} />
      </Routes>

      </div>
    </Router>
  );
}
export default App;