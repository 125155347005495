import React from "react";
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Breadcrumb from "../../Components/Breadcrumb/breadcrumb";
import ServiceList from "../../Components/Services/services";

export default function Services(){
    return(
        <React.Fragment>
            <Header/>
            <Breadcrumb title={'Services'}/>
            <ServiceList/>
            <Footer/>
        </React.Fragment>
    )
}