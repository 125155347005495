import React from "react";
import "./dashboard.css";
import Header from "../../../Components/Header/header"
import Footer from "../../../Components/Footer/footer"
import Sidebar from "../../../Components/UserSidebar"

export default function Dashboard(){
    return(
        <React.Fragment>
            <Header/>
            <section className="loginsep"></section>
            <section className="dashboard">
                <div className="container">
                    <div className="row">
                        <Sidebar/>
                        <div className="col-lg-9 col-md-9 col-sm-9 col-9">
                            <div className="myaccounttopnav">
                                    <div className="row">
                                        <div className="col-sm-4">
                                               <div className="event_card bg-globe-img">                                                    
                                                     <div className="avatar-sm flex-shrink-0">
                                                        <div className="avatar-title">
                                                        <p>100</p>
                                                        </div>
                                                     </div>
                                                    <h4>Attend Events</h4>                                                   
                                                </div> 
                                        </div>
                                        <div className="col-sm-4">
                                        <div className="event_card bg-globe-img">                                                    
                                                     <div className="avatar-sm flex-shrink-0">
                                                        <div className="avatar-title">
                                                        <p>100</p>
                                                        </div>
                                                     </div>
                                                    <h4>Attend Events</h4>                                                   
                                                </div> 
                                        </div>
                                        <div className="col-sm-4">
                                        <div className="event_card bg-globe-img">                                                    
                                                     <div className="avatar-sm flex-shrink-0">
                                                        <div className="avatar-title">
                                                        <p>100</p>
                                                        </div>
                                                     </div>
                                                    <h4>Attend Events</h4>                                                   
                                                </div>  
                                        </div>
                                       
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </React.Fragment>
    )
}