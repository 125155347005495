import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./login.css";
import Header from "../../../Components/Header/header"
import Footer from "../../../Components/Footer/footer"
import LoginBanner from "../../../Assets/aboutbanner/slide2.webp"
import { FaEnvelope, FaEye, FaLock, FaEyeSlash } from "react-icons/fa6";
import { LoginForm } from "../../../API/my-account";
import { AuthContext } from '../../../AuthContext';
import LoginWithGoogle from "../../../Components/LoginWithGoogle"

export default function Login(){
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const { login } = useContext(AuthContext);
    const togglePasswordVisibility = () => {
        setIsPasswordVisible((prevVisibility) => !prevVisibility);
    }; 
    /*------------Login Form -----------------*/
    const [formData, setFormData] = useState({
        username: '', password: ''
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const validateForm = () => {
        const errors = {};
        if (!formData.username) errors.username = 'Enter username!';
        if (!formData.password) errors.password = 'Enter password!';
        return errors;
    };
    const handleChange = ({ target: { name, value } }) => {
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        const formErrors = validateForm();
        setErrors(formErrors);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length) {
            setErrors(formErrors);
            return;
        }
        setLoading(true);
        setErrorMessage('');
        try {
            const data = await LoginForm(formData);
            setFormData({ username: '', password: '' });
            setErrors({});
            const token = data.authToken;
            const userInfo = data.data;
            login(token, userInfo);
            if (data.status === true) {
                navigate('/dashboard/');
            }
            if (data.status === false) {
                setErrorMessage('Please check email or password!');
            }
        } catch {
            setErrorMessage('Please check email or password!');
        } finally {
            setLoading(false);
            //navigate('/thank-you/');
        }
    };
    return(
        <React.Fragment>
            <Header/>
            <section className="loginsep"></section>
            <section className="loginscreen">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <div className="loginform">
                                <h2>Login</h2>
                                <p>Welcome Back! Please enter your details</p>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="email">Email <span>*</span></label>
                                        <div className="inputicons loginemail"><FaEnvelope/></div>
                                        <input type="email" id="email" name="username" className="form-control" placeholder="Enter your email" onChange={handleChange} />
                                        {errors.username && <p className="error">{errors.username}</p>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password">Password <span>*</span></label>
                                        <div className="inputicons"><FaLock/></div>
                                        <input type={isPasswordVisible ? "text" : "password"} id="password" name="password" className="form-control" placeholder="••••••" onChange={handleChange} />
                                        {errors.password && <p className="error">{errors.password}</p>}
                                        <div className="toggle-password" onClick={togglePasswordVisibility}>{isPasswordVisible ? <FaEyeSlash /> : <FaEye />}</div>
                                    </div>
                                    <div className="forgotpasswordarea">
                                        <div className="remamberpassword">
                                            {/* <input type="checkbox" id="remember" name="remember" value="1" />
                                            <label htmlFor="remember"> Remember for 30 Days</label> */}
                                        </div>
                                        <div className="forgotpasswords">
                                            <Link to="#">Forgot Password</Link>
                                        </div>
                                    </div>
                                    <div className="signinbtns">
                                        <button type="submit">Sign in</button>
                                        {errorMessage && <div className="successmessage"><p className="error">{errorMessage}</p></div>}
                                    </div>
                                </form>
                                {loading && (
                                    <div className="formloader">
                                        <div className="spinner"></div>
                                    </div>
                                )}
                                <div className="otherloginseprator">
                                    <p>OR</p>
                                </div>
                                <LoginWithGoogle/>
                                <div className="dontacount">
                                    <p>Don't have an account? <Link to="/register/">Register</Link></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="loginimagearea">
                                <img src={LoginBanner} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </React.Fragment>
    )
}