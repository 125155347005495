export const Testimonialsdata = [
    {
        testimonial_content: "The genuine conversations and shared expertise have truly enriched my professional journey and inspired new possibilities",
        testimonial_img: 'https://placehold.co/80x80',
        testimonial_name: '— Kyra, United States',
        testimonial_design: ''
    },
    {
        testimonial_content: "It's been wonderful. I feel I've talked a lot and I will definitely hop on your next meeting because I can definitely see the value",
        testimonial_img: 'https://placehold.co/80x80',
        testimonial_name: '— Jodie, UK',
        testimonial_design: ''
    },
    {
        testimonial_content: "I love them because we get to connect on a deeper level",
        testimonial_img: 'https://placehold.co/80x80',
        testimonial_name: '— Katherine, Canada',
        testimonial_design: ''
    },
    {
        testimonial_content: "It's so good to meet entrepreneurs around the world. It does not feel like the world is small anymore",
        testimonial_img: 'https://placehold.co/80x80',
        testimonial_name: '— Dina, Brunei',
        testimonial_design: ''
    },
    {
        testimonial_content: "I really love the way you guys cheered me up when I was trying to present",
        testimonial_img: 'https://placehold.co/80x80',
        testimonial_name: '— Prag, India',
        testimonial_design: ''
    }
]