import React from "react";
import { Link } from "react-router-dom";
import "./packages.css";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { RxCrossCircled } from "react-icons/rx";

export default function Packages(){
    return(
        <React.Fragment>
            <section className="packages">
                <div className="container">
                    <div className="packageshead">
                        <h3>Choose Your Package</h3>
                        <h2>Women can do everything.</h2>
                        <p>Start your membership here.</p>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                            <div className="packagegrid wow animate__animated animate__fadeInLeft">
                                <div className="packagename">
                                    <h3>Starter Kit</h3>
                                    <h4>Basic Package</h4>
                                </div>
                                {/* <div className="packageprice">
                                    <span className="currency">$</span>
                                    <span className="pricecurre">199</span>
                                    <span className="afteramount">00</span>
                                </div> */}
                                {/* <div className="packagetenor">
                                    <p>yearly</p>
                                </div> */}
                                <div className="packagebenifit">
                                    <ul>
                                        <li><IoCheckmarkCircleOutline/> <span>Daily access to community forum with themed discussion groups</span></li>
                                        <li><IoCheckmarkCircleOutline/> <span>Bi-Weekly Women Business Connect Global Networking Hour</span></li>
                                        <li><IoCheckmarkCircleOutline/> <span>10% discount on workshops and events</span></li>
                                        <li className="notinpackage"><RxCrossCircled/> <span>Two virtual conference opportunities as keynote speaker</span></li>
                                        <li className="notinpackage"><RxCrossCircled/> <span>Two one-on-one coaching/mentoring sessions per year</span></li>
                                        <li className="notinpackage"><RxCrossCircled/> <span>Access to all recorded webinars, workshops and courses</span></li>
                                        <li className="notinpackage"><RxCrossCircled/> <span>Exclusive featured article in our quarterly WBC E-Magazine, highlighted across all social media platforms.</span></li>
                                    </ul>
                                </div>
                                <div className="packagebookbtn">
                                    <Link to="#">BECOME A MEMBER</Link>
                                </div>
                                {/* <div className="packagefooter">
                                    <p>*Lorem Ipsum is simply dummy text</p>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                            <div className="packagegrid wow animate__animated animate__fadeInRight propackage">
                                <div className="packagename">
                                    <h3>Pro Kit</h3>
                                    <h4>Pro Package</h4>
                                </div>
                                {/* <div className="packageprice">
                                    <span className="currency">$</span>
                                    <span className="pricecurre">399</span>
                                    <span className="afteramount">00</span>
                                </div> */}
                                {/* <div className="packagetenor">
                                    <p>Yearly</p>
                                </div> */}
                                <div className="packagebenifit">
                                    <ul>
                                        <li><IoCheckmarkCircleOutline/> <span>All in the Basic Membership</span></li>
                                        <li><IoCheckmarkCircleOutline/> <span>Business Profile and Directory Listing</span></li>
                                        <li><IoCheckmarkCircleOutline/> <span>Monthly Mastermind Group Session</span></li>
                                        <li><IoCheckmarkCircleOutline/> <span>Conduct Monthly Workshops/Webinars</span></li>
                                        <li><IoCheckmarkCircleOutline/> <span>Two virtual conference opportunities as keynote speaker</span></li>
                                        <li><IoCheckmarkCircleOutline/> <span>Two one-on-one coaching/mentoring sessions per year</span></li>
                                        <li><IoCheckmarkCircleOutline/> <span>Access to all recorded webinars, workshops and courses</span></li>
                                        <li><IoCheckmarkCircleOutline/> <span>Exclusive featured article in our quarterly WBC E-Magazine, highlighted across all social media platforms.</span></li>
                                    </ul>
                                </div>
                                <div className="packagebookbtn">
                                    <Link to="#">BECOME A MEMBER</Link>
                                </div>
                                {/* <div className="packagefooter">
                                    <p>*Lorem Ipsum is simply dummy text</p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}
