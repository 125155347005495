import React, { useState, useContext } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { LoginWithGoogle } from "../../API/my-account";
import { AuthContext } from '../../AuthContext';

export default function UserLoginGoogle() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { login } = useContext(AuthContext);

    const handleLoginSuccess = async (credentialResponse) => {
        try {
            setLoading(true);
            setError(null);
            if (!credentialResponse?.credential) {
                throw new Error("No credential found in the response");
            }
            const decoded = jwtDecode(credentialResponse.credential);
            const userData = {
                first_name: decoded.given_name,
                last_name: decoded.family_name,
                email: decoded.email,
                photo: decoded.picture
            };

            const response = await LoginWithGoogle(userData);
            if (response.status === true) {
                const token = response.authToken;
                const userInfo = response.data;
                login(token, userInfo);
                window.location.href = '/dashboard'; 
            } else {
                throw new Error(response.message || 'Login failed');
            }
        } catch (error) {
            //console.error("Login error:", error);
            setError(error.message || "An error occurred during login");
        } finally {
            setLoading(false);
        }
    };

    const handleLoginFailure = (error) => {
        console.error("Google Login Failed:", error);
        setError("Google login failed. Please try again.");
    };

    return (
        <div className="flex flex-col items-center justify-center gap-4">
            {error && (
                <div className="text-red-500 bg-red-50 p-3 rounded-md text-sm">
                    {error}
                </div>
            )}

            {loading ? (
                <div className="flex items-center gap-2">
                    <div className="w-5 h-5 border-t-2 border-b-2 border-blue-500 rounded-full animate-spin" />
                    <span className="text-gray-600">Processing login...</span>
                </div>
            ) : (
                <div className="loginwith">
                    <GoogleLogin
                        onSuccess={handleLoginSuccess}
                        onError={handleLoginFailure}
                        useOneTap
                        shape="rectangular"
                        theme="filled_blue"
                    />
                </div>
            )}
        </div>
    );
}