import React from "react";
import CountUp from 'react-countup';
import "./home.css";
import Header from "../../Components/Header/header"
import Footer from "../../Components/Footer/footer"
import CtaBanner from "../../Components/CTA/ctabanner"
import Events from "../../Components/Events/events"
import Packages from "../../Components/Packages/packages"
import Aboutus from "../../Components/Aboutus/aboutus"
import Testimonials from "../../Components/Testimonials/testimonials"
import VideoTestimonials from "../../Components/VideoTestimonials/videotestimonials"
import { FaUsers, FaAward } from "react-icons/fa6";
import Banner from "../../Assets/banner.mp4";
import { TbTopologyStar3 } from "react-icons/tb";
import { IoPeopleSharp } from "react-icons/io5";
import { MdOutlineSupportAgent } from "react-icons/md";
import { GiWorld } from "react-icons/gi";
import { MdEventAvailable } from "react-icons/md";
import Poster from "../../Assets/banner_cover.jpeg"
import LazyLoad from 'react-lazyload';


export default function Home(){
    return(
        <React.Fragment>
           <Header/>
           <section className="bannervideo">
                <LazyLoad>
                    <video muted autoPlay loop playsInline poster={Poster}>
                        <source src={Banner} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </LazyLoad>
           </section>
           <section className="mermbercountarea">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 col-6">
                            <div className="countingarea">
                                <div className="counticons"><FaUsers/></div>
                                <div className="countcontent">
                                    <h3><CountUp start={0} end={1842} duration={2.5} /> <span>+</span></h3>
                                    <p>Connections</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 col-6">
                            <div className="countingarea">
                                <div className="counticons"><GiWorld/></div>
                                <div className="countcontent">
                                    <h3><CountUp start={0} end={68} duration={2.5} /> <span>+</span></h3>
                                    <p>Countries</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 col-6">
                            <div className="countingarea">
                                <div className="counticons"><MdEventAvailable/></div>
                                <div className="countcontent">
                                    <h3><CountUp start={0} end={50} duration={2.5} /> <span>+</span></h3>
                                    <p>Events</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 col-6">
                            <div className="countingarea no-border">
                                <div className="counticons"><FaAward/></div>
                                <div className="countcontent">
                                    <h3><CountUp start={0} end={50} duration={2.5} /> <span>+</span></h3>
                                    <p>Speakers</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           </section>
           <Aboutus/>
           <section className="empowering">
                <div className="container">
                    <div className="empoweringheading">
                        {/* <h3>Vision of</h3> */}
                        <h2>Why Choose Women Business Connect?
                        </h2>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                            <div className="empoweringgrid wow animate__animated animate__fadeInLeft">
                               
                                <div className="empoweringicon"><MdOutlineSupportAgent/></div>
                                <div className="empworingcontent">
                                    <h3>Empowerment</h3>
                                   <p>We are committed to nurturing women by providing the tools, resources and support needed for you to triumph in the business world and beyond.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                            <div className="empoweringgrid wow animate__animated animate__fadeInDown">                               
                                <div className="empoweringicon"><TbTopologyStar3/></div>
                                <div className="empworingcontent">
                                    <h3>Global Reach</h3>
                                   <p>Our global community offering you a diverse and rich network of women in business from different backgrounds, expertise and industries.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                            <div className="empoweringgrid wow animate__animated animate__fadeInRight">
                            <div className="empoweringicon"><IoPeopleSharp/></div>
                                <div className="empworingcontent">
                                    <h3>Collaborative Community</h3>
                                   <p>We foster a culture of collaboration where everyone is encouraged to contribute, share and grow together</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           </section>
           <VideoTestimonials/>
           <CtaBanner/>
           <Events/>
           <Packages/>
           <Testimonials/>
           <Footer/>
        </React.Fragment>
    )
}