import React,{useContext} from "react";
import { Link, useNavigate } from "react-router-dom";
import {DashboardMenu} from "../../Data/dashboardmenu"
import { FiLogOut } from "react-icons/fi";
import { AuthContext } from '../../AuthContext';
import { googleLogout } from "@react-oauth/google";
import { FaEnvelopeOpenText } from "react-icons/fa6";
import emptyimage from "../../Assets/no-image.jpg"

export default function Sidebar(){
    const { logout, user } = useContext(AuthContext);
    const navigate = useNavigate();
    const handleLogout = () => {
        googleLogout();
        sessionStorage.clear();
        logout();
        navigate('/login');
    };
    return(
        <React.Fragment>
            <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                <div className="usercardlogin">
                    <div className="userinfo">
                        <div className="userimgpart">
                        <img src={user?.profile_photo || emptyimage} alt={user?.first_name}referrerPolicy="no-referrer"/>
                        </div>
                        <div className="usernames">
                            <h3>{user?.first_name+' '+user?.last_name}</h3>
                            <ul>
                                <li><Link to={'mailto:'+user?.email} target="_blank"><FaEnvelopeOpenText/></Link></li>
                            </ul>
                        </div>
                    </div>
                    <ul className="dashboardnav">
                        {DashboardMenu.map((frontmenu) => (
                            <li key={frontmenu.id}><Link to={frontmenu.path}>{frontmenu.icon} {frontmenu.title}</Link></li>
                        ))}
                        <li>
                            <hr />
                        </li>
                        <li><button onClick={handleLogout} type="button"><FiLogOut className="me-2"/> Logout</button></li>
                    </ul>
                </div>
            </div>   
        </React.Fragment>
    )
}