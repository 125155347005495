import { Routes, Route } from "react-router-dom";
import Dashboard from '../Pages/Myaccount/Dashboard';
import Profile from '../Pages/Myaccount/Profile';

const DashboardRoutes = () => {
  return (
    <Routes>
      {/* Default Dashboard */}
      <Route path="" element={<Dashboard />} />
      
      {/* Profile Route */}
      <Route path="profile" element={<Profile />} />
    </Routes>
  );
};

export default DashboardRoutes;