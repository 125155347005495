import React from "react";
import "./testimonials.css";
import Sliders from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Testimonialsdata } from "./testimonialdata";
import { FaStar } from "react-icons/fa";
import LazyImage from '../LazyLoader/LazyImage';

const testimonialssetting = {
    dots: true,
    infinite: true,
    speed: 200,
    autoplay: true,
    autoplaySpeed: 10000,
    slidesToShow: 3,
    slidesToScroll: 1,
    pauseOnHover: false,
    arrows: false,
    responsive: [
        {
            breakpoint: 1100,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
            },
        },
    ]
};
export default function Testimonials(){
    return(
        <React.Fragment>
            <section className="testimonials">
                <div className="container">
                    <div className="testimonialshead">
                        <h3>Testimonial</h3>
                        <h2>What They Say</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                    </div>
                    <div className="testimonialslist">
                        <Sliders {...testimonialssetting}>
                        {Testimonialsdata.map((testimonials) => (
                            <div className="testimonialboxs" key={testimonials.id}>
                                <div className="testimonialsmeta">
                                    <div className="clientimgs">
                                        <LazyImage src={testimonials.testimonial_img} alt={testimonials.name} />
                                    </div>
                                    <div className="clientname">
                                        <h3>{testimonials.testimonial_name}</h3>
                                    </div>
                                </div>
                                <div className="testimonialreat">
                                    {[...Array(5)].map((_, index) => (
                                        <FaStar key={index} />
                                    ))}
                                </div>
                                <div className="testimonialcontent">
                                    <p>{testimonials.testimonial_content}</p>
                                </div>
                            </div>
                        ))}
                    </Sliders>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}