import React, {useEffect, useState, useContext} from "react";
import "./header.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Mainlogo from "../../Assets/mainlogo.png"
import { FaEnvelopeOpenText, FaInstagram, FaLinkedinIn, FaAngleDown, FaRegCircleUser } from "react-icons/fa6";
import LazyImage from '../LazyLoader/LazyImage';
import { FaBarsStaggered, FaPlus, FaMinus, FaAngleRight } from "react-icons/fa6";
import { FaTimes } from "react-icons/fa";
import { AuthContext } from '../../AuthContext';
import {DashboardMenu} from  "../../Data/dashboardmenu"
import { FiLogOut } from "react-icons/fi";

export default function Header(){
    const { isAuthenticated, user, logout } = useContext(AuthContext);
    const navigate = useNavigate();
    useEffect(() => {
        const header = document.querySelector('.mainheader');
        const sticky = header.offsetTop;
        const handleScroll = () => {
            if (window.scrollY > sticky) {
                header.classList.add('sticky');
            } else {
                header.classList.remove('sticky');
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    /*---------Mobile Toggles---------*/
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    /*-----------Mobile Menu---------*/
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState(null);
    const location = useLocation();

    const toggleDropdown = (dropdown) => {
        setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
    };

    const toggleSidebar = () => {
        if (isSidebarOpen) {
            setIsClosing(true);
            setTimeout(() => {
                setIsSidebarOpen(false);
                setIsClosing(false);
            }, 500);
        } else {
            setIsSidebarOpen(true);
        }
    };
    useEffect(() => {
        document.body.classList.toggle('no-scroll', isSidebarOpen);
        return () => document.body.classList.remove('no-scroll');
    }, [isSidebarOpen]);
    useEffect(() => {
        if (isSidebarOpen) {
            setIsClosing(true);
            setTimeout(() => {
                setIsSidebarOpen(false);
                setIsClosing(false);
            }, 500);
        }
    }, [location.pathname]);

    const toggleSidebarClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            setIsSidebarOpen(false);
            setIsClosing(false);
        }, 500);
    };

    const handleLogout = () => {
        logout();
        navigate('/login');
    };
    return(
        <React.Fragment>
            <section className="headertop">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6">
                            <div className="headertopleft">
                                <ul>
                                    <li><FaEnvelopeOpenText/> <Link to="mailto:hello@yourdomain.com">hello@yourdomain.com</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6">
                            <div className="socialmedia">
                                <ul>
                                    <li><Link to="http://www.instagram.com/theright.coach" target="_blank"><FaInstagram/></Link></li>
                                    <li><Link to="http://www.linkedin.com/in/hanamohamed" target="_blank"><FaLinkedinIn/></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
           <header className="mainheader">
                <div className="container">
                    <div className="headerdata">
                        <div className="logoarea">
                            <Link to="/"><LazyImage src={Mainlogo} alt="" /></Link>
                        </div>
                        {windowWidth >= 800 && (
                            <div className="menuarea">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li className="has-child"><Link to="/events/">Events</Link> <FaAngleDown/>
                                        <ul className="child-menu">
                                            <li><Link to="/events/upcoming-events/">Upcoming Events</Link></li>
                                            <li><Link to="/events/past-events/">Past Events</Link></li>
                                            <li><Link to="/global-summit-vibe/">Global Summit: V.I.B.E</Link></li>
                                        </ul>
                                    </li>
                                    <li className="has-child"><Link to="/scope-of-services/">Services</Link> <FaAngleDown/>
                                        <ul className="child-menu">
                                            <li><Link to="/business-listing/">Business Listing</Link></li>
                                            <li><Link to="/be-our-guest-speaker/">Be our Guest Speaker</Link></li>
                                            <li><Link to="/connection-call/">Connection Call</Link></li>
                                        </ul>
                                    </li>
                                    <li className="has-child"><Link to="#">Personal Strategy Coaching</Link> <FaAngleDown/>
                                        <ul className="child-menu">
                                            <li><Link to="/about-hana/">About The Right Coach</Link></li>
                                            <li><Link to="/scope-of-services/">Scope of Services</Link></li>
                                            <li><Link to="/strategy-call/">Strategy Call</Link></li>
                                            <li><Link to="/testimonials/">Testimonials</Link></li>
                                        </ul>
                                    </li>
                                    <li className="has-child"><Link to="#">Resources</Link> <FaAngleDown/>
                                        <ul className="child-menu">
                                            <li><Link to="/articles/">Articles</Link></li>
                                            <li><Link to="/e-magazine/">E-Magazine</Link></li>
                                            <li><Link to="/webinars/">Webinars</Link></li>
                                        </ul>
                                    </li>
                                    <li className="has-child"><Link to="/contact-us/">Contact us</Link> <FaAngleDown/>
                                        <ul className="child-menu">
                                            <li><Link to="#">Partner with Us</Link></li>
                                        </ul>
                                    </li>
                                </ul>
                                {isAuthenticated ? (
                                    <div className="userheadmenu">
                                        <Link to="/dashboard/">
                                            <div className="loginuserimg">
                                                <div className="usericonlogin">
                                                    <FaRegCircleUser/>
                                                </div>
                                                <div className="userinfoare">
                                                    <h3>{user?.first_name}</h3>
                                                </div>
                                                <div className="logindownarrow">
                                                    <FaAngleDown/>
                                                </div>
                                            </div>
                                        </Link>
                                        <div className="dropdownmenulogin">
                                            <ul>
                                                {DashboardMenu.map((frontmenu) => (
                                                    <li key={frontmenu.id}><Link to={frontmenu.path}>{frontmenu.icon} {frontmenu.title}</Link></li>
                                                ))}
                                                <li><button onClick={handleLogout} type="button"><FiLogOut/> Logout</button></li>
                                            </ul>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="menubutton">
                                        <Link to="/register/">Become a Member</Link>
                                        <Link to="/login/">Login</Link>
                                    </div>
                                )}
                                
                            </div>
                        )}
                        {windowWidth <= 800 && (
                            <div className="mobilemenutoggle"><FaBarsStaggered  onClick={toggleSidebar}/></div>
                        )}
                    </div>
                </div>
            </header>
            {isSidebarOpen && (
                <div className={`mobilemenusidebar ${isClosing ? 'closing' : ''}`}>
                    <div className={`mobilemenuarea ${isClosing ? 'closing' : ''}`}>
                        <button class="th-menu-toggle close_menu" onClick={toggleSidebarClose}><FaTimes/></button>
                        <div className="mobilemenulogoarea">
                            <Link to="/"><img src={Mainlogo} alt="Main Logo" /></Link>
                        </div>
                        <div className="mobilemenuitems">
                            <ul>
                                <li><FaAngleRight/><Link to="/">Home</Link></li>
                                <li className={`mobile-submenu ${activeDropdown === 'events' ? 'active' : ''}`}><FaAngleRight/><Link to="#" onClick={() => toggleDropdown('events')}>Events</Link> {activeDropdown === 'events' ? <FaMinus className="icon-minus" onClick={() => toggleDropdown('events')}/> : <FaPlus  onClick={() => toggleDropdown('events')}/>}
                                    {activeDropdown === 'events' && (
                                        <ul className="child-mobile">
                                            <li><FaAngleRight/><Link to="/events/upcoming-events/">Upcoming Events</Link></li>
                                            <li><FaAngleRight/><Link to="/events/past-events/">Past Events</Link></li>
                                        </ul>
                                    )}
                                </li>
                                <li className={`mobile-submenu ${activeDropdown === 'services' ? 'active' : ''}`}><FaAngleRight/><Link to="#" onClick={() => toggleDropdown('services')}>Services</Link> {activeDropdown === 'services' ? <FaMinus className="icon-minus" onClick={() => toggleDropdown('services')}/> : <FaPlus  onClick={() => toggleDropdown('services')}/>}
                                    {activeDropdown === 'services' && (
                                        <ul className="child-mobile">
                                            <li><FaAngleRight/><Link to="/business-listing/">Business Listing</Link></li>
                                            <li><FaAngleRight/><Link to="/be-our-guest-speaker/">Be our Guest Speaker</Link></li>
                                            <li><FaAngleRight/><Link to="/connection-call/">Connection Call</Link></li>
                                        </ul>
                                    )}
                                </li>
                                <li className={`mobile-submenu ${activeDropdown === 'personal_coaching' ? 'active' : ''}`}><FaAngleRight/><Link to="#" onClick={() => toggleDropdown('personal_coaching')}>Personal Strategy Coaching</Link> {activeDropdown === 'personal_coaching' ? <FaMinus className="icon-minus" onClick={() => toggleDropdown('personal_coaching')}/> : <FaPlus  onClick={() => toggleDropdown('personal_coaching')}/>}
                                    {activeDropdown === 'personal_coaching' && (
                                        <ul className="child-mobile">
                                            <li><FaAngleRight/><Link to="/about-hana/">About The Right Coach</Link></li>
                                            <li><FaAngleRight/><Link to="/scope-of-services/">Scope of Services</Link></li>
                                            <li><FaAngleRight/><Link to="/strategy-call/">Strategy Call</Link></li>
                                            <li><FaAngleRight/><Link to="/testimonials/">Testimonials</Link></li>
                                        </ul>
                                    )}
                                </li>
                                <li className={`mobile-submenu ${activeDropdown === 'resources' ? 'active' : ''}`}><FaAngleRight/><Link to="#" onClick={() => toggleDropdown('resources')}>Resources</Link> {activeDropdown === 'resources' ? <FaMinus className="icon-minus" onClick={() => toggleDropdown('resources')}/> : <FaPlus  onClick={() => toggleDropdown('resources')}/>}
                                    {activeDropdown === 'resources' && (
                                        <ul className="child-mobile">
                                            <li><FaAngleRight/><Link to="/articles/">Articles</Link></li>
                                            <li><FaAngleRight/><Link to="/e-magazine/">E-Magazine</Link></li>
                                            <li><FaAngleRight/><Link to="/webinars/">Webinars</Link></li>
                                        </ul>
                                    )}
                                </li>
                                <li><FaAngleRight/><Link to="/contact-us/">Contact us</Link></li>
                            </ul>
                        </div>
                        <div className="mobilemenubutton">
                            <Link to="/contact-us/">Become a Member</Link>
                            <Link to="/login/">Login</Link>
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    )
}