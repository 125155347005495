import React from "react";
import { Link } from "react-router-dom";
import "./businesslisting.css";
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Breadcrumb from "../../Components/Breadcrumb/breadcrumb";
import { FaLink, FaLinkedinIn } from "react-icons/fa6";

export default function BusinessListing(){
    return(
        <React.Fragment>
            <Header/>
            <Breadcrumb title={'Business Listing'}/>
            <section className="businesslisting">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="businessfilter">
                                <div className="filterbusiness">
                                    <h2>Filter</h2>
                                    <div className="filtergroup">
                                        <label htmlFor="searchbyname" className="mt-0">Search By Name</label>
                                        <input type="text" name="searchbyname" id="searchbyname" className="form-control" />
                                    </div>
                                    <div className="filtergroup">
                                        <label htmlFor="searchbycompany">Search By Company</label>
                                        <input type="text" name="searchbycompany" id="searchbycompany" className="form-control" />
                                    </div>
                                    <div className="filtergroup">
                                        <label htmlFor="searchbyindustry">Search By Industry</label>
                                        <input type="text" name="searchbyindustry" id="searchbyindustry" className="form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="busineelistarea">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="businesslistgrid">
                                            <div className="businessimg">
                                                <img src="https://placehold.co/500x500" alt="" />
                                            </div>
                                            <div className="businesstitle">
                                                <h2>Ms. Hanna Mohamed</h2>
                                                <h3><Link to="#" target="_blank">The Right Coach</Link></h3>
                                                <h4>Founder / CEO</h4>
                                            </div>
                                            <div className="businessdisc">
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                            </div>
                                            <div className="businessbuttons">
                                                <Link to="#" target="_blank"><FaLink/></Link>
                                                <Link to="#" target="_blank"><FaLinkedinIn/></Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="businesslistgrid">
                                            <div className="businessimg">
                                                <img src="https://placehold.co/500x500" alt="" />
                                            </div>
                                            <div className="businesstitle">
                                                <h2>Mr. Amit Kumar</h2>
                                                <h3><Link to="#" target="_blank">Blue Digital Technologies</Link></h3>
                                                <h4>Business Head / BDM</h4>
                                            </div>
                                            <div className="businessdisc">
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                            </div>
                                            <div className="businessbuttons">
                                                <Link to="https://bluedigitaltechnologies.com/" target="_blank"><FaLink/></Link>
                                                <Link to="https://www.linkedin.com/company/bluedigitaltechnologies/" target="_blank"><FaLinkedinIn/></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </React.Fragment>
    )
}