import React, { createContext, useState, useEffect, useCallback, useRef } from 'react';

export const AuthContext = createContext();

const AUTO_LOGOUT_TIME = 2 * 60 * 60 * 1000;

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const timeoutIdRef = useRef(null);

    const logout = useCallback(() => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('user');
        setIsAuthenticated(false);
        setUser(null);

        if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
            timeoutIdRef.current = null;
        }
    }, []);

    const resetTimer = useCallback(() => {
        if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
        }

        timeoutIdRef.current = setTimeout(() => {
            logout();
        }, AUTO_LOGOUT_TIME);
    }, [logout]);

    const login = useCallback((token, userInfo) => {
        localStorage.setItem('authToken', token);
        localStorage.setItem('user', JSON.stringify(userInfo));
        setIsAuthenticated(true);
        setUser(userInfo);
        resetTimer(); 
    }, [resetTimer]);

    useEffect(() => {
        const token = localStorage.getItem('authToken');
        const userData = localStorage.getItem('user');

        if (token && userData) {
            setIsAuthenticated(true);
            setUser(JSON.parse(userData));
            resetTimer();
        }

        return () => {
            if (timeoutIdRef.current) {
                clearTimeout(timeoutIdRef.current);
            }
        };
    }, [resetTimer]);

    return (
        <AuthContext.Provider value={{ isAuthenticated, user, login, logout, resetTimer }}>
            {children}
        </AuthContext.Provider>
    );
};