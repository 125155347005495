import { FaRegCircleUser, FaD, FaClipboardCheck, FaMoneyBillTransfer, FaLock } from "react-icons/fa6";

export const DashboardMenu = [
    {
        id: '1',
        title: 'Dashboard',
        icon: <FaD className="me-2"/>,
        path: '/dashboard/',
    },
    {
        id: '2',
        title: 'Profile',
        icon: <FaRegCircleUser className="me-2" />,
        path: '/dashboard/profile/',
    },
    {
        id: '3',
        title: 'Events',
        icon: <FaClipboardCheck className="me-2" />,
        path: '/dashboard/events/',
    },
    {
        id: '4',
        title: 'Transactions',
        icon: <FaMoneyBillTransfer className="me-2" />,
        path: '/dashboard/transactions/',
    },
    {
        id: '5',
        title: 'Change Password',
        icon: <FaLock className="me-2" />,
        path: '/dashboard/change-password/',
    }
];