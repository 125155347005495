import React from "react";
import { Link } from "react-router-dom";
import "./services.css";
import { BsGraphUpArrow } from "react-icons/bs";
import { SiNintendogamecube } from "react-icons/si";
import { GrBusinessService } from "react-icons/gr";
import { FaEye } from "react-icons/fa";
import { IoNavigate } from "react-icons/io5";

export default function Services(){
    return(
        <React.Fragment>
            <section className="services">
                <div className="container">
                    <div className="serviceshead">
                        <h3>What we offer</h3>
                        <h2>Scope Our Services</h2>
                        <p>These coaching programs actually help you skyrocket your process to achieve your business or career goals faster,</p>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-4">
                            <div className="servicegrid wow animate__animated animate__fadeInLeft">
                                <div className="serviceicons"><BsGraphUpArrow/></div>
                                <div className="servicecontent">
                                    <h3><Link to="#">Startup Success</Link></h3>
                                    <p>If you do not know or not sure where to start or unable to make your decision,then with this strategy creation, you will be lead to clarity by navigating your path to solutions and ideas towards your business</p>
                                    <div className="servicereadmore">
                                        <Link to="#">Learn More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="servicegrid wow animate__animated animate__fadeInDown">
                                <div className="serviceicons"><SiNintendogamecube/></div>
                                <div className="servicecontent">
                                    <h3><Link to="#">Game Changer</Link></h3>
                                    <p>A  strategy program for ambitious business owners who are struggling to maintain consistency in tasks and client generation. This program aligns your strong desire with your game changing actions</p>
                                    <div className="servicereadmore">
                                        <Link to="#">Learn More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="servicegrid wow animate__animated animate__fadeInRight">
                                <div className="serviceicons"><GrBusinessService/></div>
                                <div className="servicecontent">
                                    <h3><Link to="#">Progressive Innovator</Link></h3>
                                    <p>If you are losing motivation by being monotonous in your business, this program would be a better choice. Your focus to Progress to next level can be unlocked with this strategy</p>
                                    <div className="servicereadmore">
                                        <Link to="#">Learn More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="servicegrid wow animate__animated animate__fadeInLeft">
                                <div className="serviceicons"><FaEye/></div>
                                <div className="servicecontent">
                                    <h3><Link to="#">Visionary Accelerator</Link></h3>
                                    <p>You know that your business is about to scale but you are confused about delegation. If you are looking worried about lonely burn out, then check this out.</p>
                                    <div className="servicereadmore">
                                        <Link to="#">Learn More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="servicegrid wow animate__animated animate__fadeInRight">
                                <div className="serviceicons"><IoNavigate/></div>
                                <div className="servicecontent">
                                    <h3><Link to="#">Personal Strategy Navigator</Link></h3>
                                    <p>Looking to boost your confidence to lead your team by knowing your values and goals, this program can help you find you personal strategy to make decisions without compromise on your values. </p>
                                    <div className="servicereadmore">
                                        <Link to="#">Learn More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}