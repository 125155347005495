import React from "react";
import { Link } from "react-router-dom";
import "./ctabanner.css";
import Ctavideo from "../../Assets/ctavideo.mp4"
import { FaLinkedinIn, FaWhatsapp } from "react-icons/fa6";

export default function Ctabanner(){
    return(
        <React.Fragment>
            <section className="ctaarea">
                <div className="ctavideo">
                    <video muted autoPlay loop playsInline >
                        <source src={Ctavideo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className="ctacontentarea">
                    <div className="container">
                        <div className="ctacontent">
                            <h2>Join Our Global Community</h2>
                            <p>Imagine a platform where women from all corners of the globe come together to share their experiences, insights and support. Women Business Connect is dedicated to creating a vibrant, global network of women who are passionate about business and eager to make meaningful connections. Our networking hour is a unique opportunity for you to connect with like-minded women, build lasting business friendships and discover new opportunities for growth and success.
                            </p>
                            <div className="ctabtns">
                                <Link to="https://www.linkedin.com/groups/13004254/" target="_blank"><FaLinkedinIn/> Connect on Linkedin</Link>
                                <Link to="https://chat.whatsapp.com/IaPvkjmkHWOGHe2rmfmAnU" target="_blank"><FaWhatsapp/> Connect on WhatsApp</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}