import React from "react";
import { Link } from "react-router-dom";
import "./error.css";
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Breadcrumb from "../../Components/Breadcrumb/breadcrumb";

export default function Error(){
    return(
        <React.Fragment>
            <Header/>
            <Breadcrumb title={'Page Not Found'}/>
            <section className="errorse">
                <div className="container">
                    <div className="errorcontent">
                        <h2>4<span>0</span>4</h2>
                        <h3>Page Not Found</h3>
                        <p>The page you were looking for could not be found.</p>
                        <div className="errobtns">
                            <Link to="/">Go To Home</Link>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </React.Fragment>
    )
}