import React from "react";
import "./aboutus.css";
import { Link } from "react-router-dom";
import Sliders from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slide1 from "../../Assets/aboutbanner/slide1.webp";
import slide2 from "../../Assets/aboutbanner/slide2.webp";
import slide3 from "../../Assets/aboutbanner/slide3.webp";
import slide4 from "../../Assets/aboutbanner/slide4.webp";
import LazyImage from '../LazyLoader/LazyImage';


const aboutsetting = {
    dots: true,
    infinite: true,
    speed: 200,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    arrows: false,
    responsive: [
      {
          breakpoint: 800,
          settings: {
            slidesToShow: 1,
          },
      },
      {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
          },
      },
    ]
};

export default function Aboutus(){
    return(
        <React.Fragment>
            <section className="abouthome">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-lg-6 col-md-7 col-sm-12 col-xs-12 col-12">
                            <div className="aboutcontent wow animate__animated animate__fadeInLeft">
                                <h3>About Us</h3>
                                <h2>WE ARE WOMEN BUSINESS CONNECT</h2>
                                
                                <p>Are you paving your way in the world of business, or maybe just starting with an idea? At Women Business Connect, we’re here to support and empower you.</p>
                                <p>Since we started in March 2024, our mission has been to empower and support women in business through an inclusive and collaborative community that cultivates professional growth, meaningful connections and opportunities for success in a safe space.</p>
                                <p>Imagine a space where you’re truly understood—a place where every connection lifts you higher and every conversation is safe, uplifting, and inspiring. Whether you’re launching, scaling, or simply exploring your vision, you’ll find support, inspiration, and encouragement here.</p>
                                <p>If you’re ready to grow, connect, and succeed with women who have your back, we welcome you to join Women Business Connect. Together, let’s make your vision a reality.</p>
                                <div className="joinusbtn">
                                    <Link to="#">BECOME A MEMBER</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 col-12">
                            <div className="aboutimage wow animate__animated animate__fadeInRight">
                                <div className="aboutslider">
                                    <Sliders {...aboutsetting}>
                                        <div className="aboutimg">
                                            <LazyImage src={slide1} alt="" />
                                        </div>
                                        <div className="aboutimg">
                                            <LazyImage src={slide2} alt="" />
                                        </div>
                                        <div className="aboutimg">
                                            <LazyImage src={slide3} alt="" />
                                        </div>
                                        <div className="aboutimg">
                                            <LazyImage src={slide4} alt="" />
                                        </div>
                                    </Sliders>
                                </div>
                                {/* <div className="aboutvideobtn">
                                    <div className="videobtns"><FaCirclePlay/></div>
                                    <h4>We are women business connect</h4>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
           </section>
        </React.Fragment>
    )
}